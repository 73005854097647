import React from "react";
import styled from "styled-components";
import Flex from "app/components/Flex";
import { GetInstructorProfileBySlugQuery } from "services/graphql";

const DescriptionCard = styled.div`
  white-space: pre-line;
  border-width: 3px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.lightGrey};
  display: inline-block;
  margin-bottom: 20px;
  padding: 30px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`;

const Title = styled.h4`
  margin: 0 0 20px;
  font-size: 18px;
`;

export const InstructorBody = ({
  instructor,
}: {
  instructor: GetInstructorProfileBySlugQuery["instructorBySlugPublic"];
}) => (
  <Flex flexDirection="column" my={3}>
    <Flex flexDirection="column" flex="1" py={[3, 3, 0]} px="3">
      <DescriptionCard>
        <Title>Credits</Title>
        {instructor.credits?.join(", ")}
      </DescriptionCard>
      <DescriptionCard>
        <Title>About {instructor.name}</Title>
        {instructor.bio ? instructor.bio : "Coming soon."}
      </DescriptionCard>
    </Flex>
  </Flex>
);
