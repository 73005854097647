import React from "react";
import { useParams, Redirect } from "react-router-dom";
import { useGetInstructorProfileBySlugQuery } from "services/graphql";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { Helmet } from "react-helmet-async";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { PageTitle } from "app/components/RouteComponents";
import { InstructorClasses } from "app/components/InstructorClasses";
import env from "helpers/env";
import Header from "./Header";
import { InstructorBody } from "./Body";

export default function InstructorProfile(): JSX.Element {
  const { slug } = useParams<{ slug: string }>();
  const { data, loading, error } = useGetInstructorProfileBySlugQuery({
    variables: { slug: slug ?? "" },
    skip: !slug,
  });

  if (loading) {
    return <LoaderCentered width={50} />;
  }

  if (error || !data?.instructorBySlugPublic) {
    return <Redirect to="/library/instructors" />;
  }

  const instructor = data.instructorBySlugPublic;

  // Redirect to user profile if instructor has a username
  if (instructor.userProfile?.username) {
    return <Redirect to={`/@${instructor.userProfile.username}`} />;
  }

  return (
    <Div pb={32}>
      <Helmet>
        <title>{instructor.name} | STEEZY</title>
        <meta
          name="description"
          content={`Learn to dance from ${instructor.name}. Dance credits: ${instructor.credits}`}
        />
        <meta
          property="og:url"
          content={`https://${env("PUBLIC_DOMAIN")}/library/instructors/${
            instructor.slug
          }`}
        />
        <meta
          property="og:title"
          content={`${instructor.name} | Online Dance Classes and Tutorials`}
        />
      </Helmet>

      <Flex justifyContent="center" position="relative">
        <Div width="100%" maxWidth="1280px">
          <Header instructor={instructor} />
          <InstructorBody instructor={instructor} />
          <React.Fragment>
            <Div px={3} mb={2}>
              <PageTitle>Recent Classes</PageTitle>
            </Div>
            <Div px={3}>
              <InstructorClasses
                instructorName={instructor?.name}
                selectedFrom="RecentClasses"
                hitsPerPage={8}
              />
            </Div>
          </React.Fragment>
        </Div>
      </Flex>
    </Div>
  );
}
